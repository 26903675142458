import React, { useState, useEffect } from "react";
import "./index.css";

function App() {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length >= 3) return ".";
        return prevDots + ".";
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="app">
      <p className="loading">Loading{dots}</p>
    </div>
  );
}

export default App;
